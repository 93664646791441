$imgAPI-medical0: "https://via.placeholder.com/570x562/1071ef/FFFFFF";
$imgAPI-medical1: "https://i.ibb.co/nnYJ3ZB/about-nzdnjx.jpg";
$imgAPI-medical2: "https://via.placeholder.com/490x327/ffc999/FFFFFF";
$imgAPI-medical3: "https://via.placeholder.com/490x327/ffc999/FFFFFF";
$imgAPI-medical4: "https://via.placeholder.com/490x327/ffc999/FFFFFF";
$imgAPI-medical5: "https://via.placeholder.com/490x327/ffc999/FFFFFF";
$imgAPI-medical6: "https://via.placeholder.com/490x327/ffc999/FFFFFF";
$imgAPI-medical7: "https://avatars.dicebear.com/api/avataaars/as.svg";
$imgAPI-medical8: "https://avatars.dicebear.com/api/avataaars/as.svg";
$imgAPI-medical9: "https://avatars.dicebear.com/api/avataaars/as.svg";
$imgAPI-medical10: "https://avatars.dicebear.com/api/avataaars/qwert.svg";
$imgAPI-medical11: "https://avatars.dicebear.com/api/avataaars/qwert.svg";
$imgAPI-medical12: "https://avatars.dicebear.com/api/avataaars/qwert.svg";
$imgAPI-medical13: "https://i.ibb.co/1dcL4P5/doctor-big-vnuayx.jpg";