$frame-deco: '../images/decoration/frame-deco.png';

/* Default Card */
.default-card {
  direction: ltr;
  border-radius: 12px;
  width: 240px;
  height: 380px;
  margin: spacing(3, 2);
  figure {
    border-radius: 0 0 50px 0;
    overflow: hidden;
    height: 170px;
    margin-bottom: $spacing3;
    img {
      height: 100%;
      min-width: 100%
    }
  }
  .text {
    padding: spacing(0, 2.5);
  }
  h6 {
    margin-bottom: $spacing2
  }
  p {
    height: 80px;
    overflow: hidden;
    margin-bottom: $spacing3
  }
  .button {
    width: 50%;
    @include margin-left(50%);
    height: 43px;
    line-height: 44px;
    position: absolute;
    right: 0;
    bottom: 0;
    @include shadow10;
    [dir="ltr"] & {
      border-radius: 20px 0 0 0;
    }
    [dir="rtl"] & {
      border-radius: 0 20px 0 0;
    }
  }
}

/* Testimonial Card */
.testi-card {
  direction: ltr;
  position: relative;
  .paper.card {
    padding: spacing(6, 2, 0, 0);
    width: 300px;
    height: 300px;
    border-radius: 0 50% 50% 50%;
    p {
      padding: $spacing3;
      height: 100px;
      overflow: hidden;
    }
  }

  .star-icon {
    color: #FFC107
  }
  
  .rating {
    margin-left: $spacing3
  }
  
  .star-icon-disable {
    .theme--light & {
      color: $light-divider;
    }
    .theme--dark & {
      color: $dark-divider;
    }
  }

  .person {
    display: flex;
    margin-top: $spacing12 * -1;
    justify-content: flex-end;
    position: relative;
    @include margin-right($spacing5);
    .avatar {
      width: 70px;
      height: 70px;
    }
    .name {
      text-align: right;
      @include margin-left($spacing2);
      h6 {
        font-size: 16px;
        font-weight: $font-medium;
      }
    }
    .caption {
      font-weight: $font-regular
    }
  }

  .avatar {
    @include shadow8;
    border: 1px solid #FFF;
    box-sizing: content-box;
    @include margin-left($spacing1);
    border-radius: 50% 50% 0 50%;
  }
}


/* Profile Card */
.card.profile {
  overflow: visible;
  .person {
    display: flex;
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 0 50% 50% 50%;
      position: relative;
      top: -8px;
      @include left(-8px);
    }
    .name {
      padding: $spacing1
    }
    h4 {
      font-size: 22px;
    }
    p {
      font-size: 16px;
      @include palette-text-secondary
    }
  }
  .divider {
    @include palette-divider;
    background: none;
    margin: spacing(0, 2)
  }
  .action {
    padding: $spacing2;
    @include breakpoints-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .property {
      span {
        @include palette-text-disabled;
        font-size: 14px;
        display: inline-block;
        @include margin-right($spacing4);
        i {
          font-size: 24px;
        }
      }
    }
    .btn {
      @include breakpoints-up(sm) {
        width: 100px
      }
      @include breakpoints-down(xs) {
        width: 100%;
        margin-top: $spacing2
      }
    }
  }
}

/* Address Card */
.address-card {
  .paper {
    padding: spacing(2, 0, 3);
  }

  .icon {
    color: $palette-primary-main;
    @include margin-right($spacing1);
    margin-top: $spacing2;
    top: 4px;
    position: relative
  }
}

