.form-page {
  .form-wrap {
    margin-top: $spacing10;
    position: relative;
    text-align: center;
    @include palette-text-secondary;
    h3 {
      font-weight: $font-bold;
      .theme--dark & {
        color: $palette-primary-light
      }
      .theme--light & {
        color: $palette-primary-dark
      }
      @include breakpoints-down(md) {
        font-size: 36px
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: $spacing5;
    &.logo-header {
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 10;
    }
    img {
      width: 64px
    }
    p, span {
      display: block;
      font-size: 24px;
      padding-bottom: 4px;
      font-weight: $font-bold;
    }
  }

  .desc {
    margin-bottom: 0;
    @include breakpoints-up(md) {
      font-size: 20px;
      padding: spacing(0, 10);
    }
  }

  .input {
    width: 100%
  }

  .form {
    @include text-align(left);
    position: relative;
    margin-top: $spacing5;
    padding-bottom: $spacing10;
    @include breakpoints-up(lg) {
      padding: spacing(0, 15, 10)
    }
  }

  .btn-area {
    margin-top: $spacing5;
    label {
      @include text-align(left)
    }
    @include breakpoints-up(sm) {
      display: flex;
      justify-content: space-between
    }
    @include breakpoints-down(xs) {
      text-align: center;
      button {
        margin-top: $spacing5
      }
    }
    span {
      a {
        color: $palette-primary-main
      }
    }
    .btn {
      i {
        [dir="rtl"] & {
          transform: scale(-1)
        }
      }
    }
  }

  .decoration {
    position: fixed;
    width: 100%;
    height: 120%;
    top: 0;
    @include left(0);
    overflow: hidden;
    .oval {
      @include use-theme(background-color, $palette-primary-light, $palette-primary-dark);
      width: 1000px;
      height: 1000px;
      border-radius: 50%;
      position: absolute;
      @include left(-40%);
      top: -100px;
      opacity: 0.2;
    }
    .capsul {
      width: 900px;
      height: 500px;
      border-radius: 500px;
      transform: rotate(-30deg);
      position: absolute;
      opacity: 0.75;
      @include use-theme(background-image, linear-gradient(144deg, $palette-primary-light 0%, $palette-secondary-light 100%), linear-gradient(144deg, $palette-primary-dark 0%, $palette-secondary-dark 100%));
      @include breakpoints-down(md) {
        opacity: 0
      }
    }
    .circle {
      width: 700px;
      height: 700px;
      border-radius: 50%;
      border: 1px solid;
      @include use-theme(border-color, $palette-primary-light, $palette-primary-dark);
      position: absolute;
      @include breakpoints-down(md) {
        opacity: 0
      }
    }
    .deco-top {
      .capsul {
        top: -200px;
        @include right(-50%)
      }
      .circle {
        top: -30%;
        @include right(-30%)
      }
    }

    .deco-bottom {
      .capsul {
        bottom: -30%;
        @include left(-50%);
      }
      .circle {
        bottom: -30%;
        @include left(-30%);
      }
    }
  }

  .right-icon {
    @include margin-left($spacing1)
  }

  .form-control-label {
    > div {
      margin-top: 0
    }
  }

  .backtohome {
    width: 80px;
    height: 80px;
    position: absolute;
    @include left(60px);
    top: -60px;
    @include breakpoints-down(sm) {
      @include left(calc(50% - 40px));
      top: -120px
    }
    span {
      width: 100%;
      height: 100%;
      display: block;
      line-height: 80px;
    }
    i {
      line-height: 82px;
      font-size: 36px;
      @include palette-text-disabled;
    }
    > i:first-child {
      opacity: 1;
      transition: opacity 0.3s ease
    }
    > span i:last-child {
      position: absolute;
      @include right(0);
      opacity: 0;
      transition: all 0.3s ease
    }
    &:hover {
      > span i:first-child {
        opacity: 0;
      }
      > span i:last-child {
        @include right(27px);
        opacity: 1;
      }
    }
  }
}
.toast {
  @include padding-right($spacing1);
  border-radius: 4px;
  width: auto;
  .toast-action {
    margin-right: 0;
  }
  i.material-icons {
    color: #FFF
  }
}