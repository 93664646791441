#ask-doctors {
  .deco {
    @include use-theme(background-color, $palette-primary-light, $palette-primary-dark);
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    position: absolute;
    @include left(-300px);
    top: -100px;
    opacity: 0.2;
  }
  .root {
    position: relative;
    z-index: 10;
    .nav{
      @include breakpoints-down(sm) {
        display: flex;
        overflow: auto;
        flex-wrap: nowrap;
      }
    }
  }

  .side-filter {
    .nav {
      background: none;
      @include breakpoints-down(xs) {
        padding: spacing(0, 3)
      }
    }
    .collection {
      border: none;
    }
    .filter {
      border-radius: 40px;
      text-transform: capitalize;
      margin-bottom: $spacing1;
      width: 90%;
      padding: 0;
      min-height: 40px;
      background: none;
      border: none;
      > a {
        padding: spacing(0.5, 2);
        display: block;
        line-height: 32px;
        overflow: hidden;
      }
      @include breakpoints-up(sm) {
        overflow: hidden;
      }
      @include breakpoints-down(sm) {
        > a {
          text-align: center
        }
      }
    }
    .active{
      background: $palette-primary-light;
      color: $palette-primary-dark;
    }
  }

  .massonry {
    button {
      width: 100%
    }
  }
}
